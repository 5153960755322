#imgIcon{
  width: 75%;
}

.centeredContainer {
  display: block;
  justify-content: center;
  align-items: center;
}

.roundedIcon {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 180px; /* Adjust the size of the round div */
  height: 180px; /* Adjust the size of the round div */
  border-radius: 50%;
  margin: 40px; /* Adjust the margin between the round divs */
  cursor: pointer;
}
.icon{
width: 7em; 
  height: 7em; 
  border-radius: 50%;
  background-image: linear-gradient(
    to right bottom,
    #684e05 10%,
    #b5a85e,
    #a17c17,
    #684e05
  );
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconDescription {
    margin-top: 15px; 
    text-align: center;
}

@media (max-width: 767px) {
  #imgIcon{
    width: 55%;
  }
  .roundedIcon {
    width: 120px; /* Adjust the size of the round div */
    height: 120px;
    margin: 30px;
  }
  .icon{
    width: 4em;
    height: 4em;
  }
  .iconDescription{
    font-size: 14px;
  }
}
