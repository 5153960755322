.map-responsive {
  padding-top: 40px;
  background-color: transparent;
  color: #242224;
  padding-bottom: 40px;
}

.map-responsive iframe {
  padding: 40px;
  border-radius: 60px;
}
@media (max-width: 767px) {
  .map-responsive iframe {
    padding: 20px;
    height: 400px;
    border-radius: 35px;
  }
  .map-responsive{
    padding-bottom: 20px;
  }
}