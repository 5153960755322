.press {
  display: block;  /* Remove bullet points; allow greater control of positioning */
  padding: 0;      /* Override defaults for lists */
  margin: 0;       /* Override defaults for lists */
  width: 100%;    /* Get the row full width */
}
.daba{
  padding-left: 3rem;
  margin-left: 3rem;;
  padding-bottom: 5rem;
}
.press-logo{
  padding: 10px;
}
.press li span {
  position: relative;
  left: 3rem;
  /* top: 0rem; */
  bottom: 2.5rem;
 } /* Adjust this value to align the parallel text with the first line */


.press li {
  display: inline-block; /* Get all images to show in a row */
  width: 33.33333%;            /* Show 4 logos per row */
  text-align: left;  
  font-family: 'Cinzel', serif ;
  font-size: 20px;
} 

@media (max-width: 960px) and (min-width: 321px) {
  .press li { 
    width: 50%;
    font-size: 17px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    padding: 8px;
    margin-bottom: 7px;
    padding-left: 0.4rem; /* Adjust the padding to position text */
    position: relative;
    line-height: 1.2rem;
  } 
  .press li span {
    position: absolute;
    left: 2.7rem;
    top: 0.9rem;
   } /* Adjust this value to align the parallel text with the first line */
  
  .press{
    margin: 0.2rem;
  }
  .press-logo{
    padding: 5px;
  }
  .daba{
    padding-left: 0rem;
    margin-left: 0rem;
    padding-bottom: 3rem;
  }
}

/* @media (max-width: 450px) {
  .press li { 
    width: 100%; 
    font-size: 10px !important;
  } 
} */