.aboutOwners {
  position: relative;
  background: #f2f2f2;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
}
.rows {
    padding: 4rem 0rem 5rem;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
.imageContainer {
  position: relative;
  z-index: 1;
}
.lowerDiv {
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  border-right: 26px solid #916c08;
  border-bottom: 26px solid #916c08;
  z-index: -1;
}
.rightGrid{

    color: #242222;
    padding: 6em;
    align-items: center;
}
.ownersContent{
    text-align: left;
    padding-top: 8rem;
    font-size: 20px;
}

@media (max-width: 767px) {
    .rows {
      padding: 3rem;
    }
    .lowerDiv{
      bottom: -15px;
    right: -15px;
    width: calc(
      100% - 15px
    ); 
    height: calc(
      100% - 15px
    ); 
    border-right: 16px solid #916c08;
    border-bottom: 16px solid #916c08;
    }
    .rightGrid{
        padding: 2rem 0rem 1rem;
        margin-top: 20px;
    }
    .ownersContent{
        padding-top: 0rem;
        font-size: medium;
        text-align: center;
        line-height: 1.5rem;
    }
}
  