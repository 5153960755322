.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 0px;
}

.offcanvas {
  background-color: #d29f14;
  max-width: 53%;
}
.groupItem {
  padding: 20px;
  background-color: #d29f14;
  font-size: 17px;
  width: 100%;
  text-align: center;
  list-style-type: none;
}
.offcanvasLogo {
  padding-left: 10px;
}
.offcanvas-body{
  padding: 1rem 0rem 1rem;
}

a.active {
  padding-bottom: 5px;
  border-bottom: 3px solid #48360b;
}
.links {
  text-decoration: none;
  color: #48360b;
  font-size: larger;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
.sideLinks{
  text-decoration: none;
  color: #242222;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
}
a.sideLinks:hover{
  color: #242222;
}

.navColor {
  /* background-color: #E6B325; */
  background-image: linear-gradient(
    to right,
    #916c08 10%,
    #efecdb,
    #d29f14,
    #916c08
  );
  margin-top: 130px;
  transition: 0.8s;
  display: block;
}

.shrinkBar {
  background-image: linear-gradient(
    to right,
    #916c08 10%,
    #efecdb,
    #d29f14,
    #916c08
  );
  margin-top: 0px;
  transition: 0.8s;
  display: block;
  font-size: 16px;
  display: flex;
}

.logoSize {
  width: 28%;
}

a:hover {
  color: #62540e;
}
.active-link {
  color: #62540e;
  font-weight: bold;
}
#banner {
  font-size: smaller;
}
.btn-close{
  background-color: #ffbb00;
}

@media (max-width: 576px) and (min-width: 371px)
{
  #logo {
    text-align: center;
  }
  a.active {
    color: #efecdb;
    padding-bottom: 5px;
    border-bottom: 3px solid #efecdb;
  }
  .navColor {
    height: 26px;
    transition: 0.5s;
  }
  .shrinkBar {
    background-image: linear-gradient(
      to right,
      #916c08 10%,
      #efecdb,
      #d29f14,
      #916c08
    );
    transition: 0.5s;
    font-size: 16px;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .navLogoCenter {
    justify-content: center;
    align-items: center;
    margin-left: 35px;
    margin-bottom: -1.8rem;
  }
  .logoSize {
    width: 42%;
    /* margin-top: -5px; */
  }
  .navbar-toggler {
    padding: 0px 8px 7px;
    margin-top: -10px;
  }
}
@media (min-width: 346px) and (max-width: 370px){
  #logo {
    text-align: center;
  }
  a.active {
    color: #efecdb;
    padding-bottom: 5px;
    border-bottom: 3px solid #efecdb;
  }
  .navColor {
    height: 26px;
    transition: 0.5s;
  }
  .shrinkBar {
    background-image: linear-gradient(
      to right,
      #916c08 10%,
      #efecdb,
      #d29f14,
      #916c08
    );
    transition: 0.5s;
    font-size: 16px;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .navLogoCenter {
    justify-content: center;
    align-items: center;
    margin-left: 35px;
    margin-bottom: -2rem;
  }
  .logoSize {
    width: 45%;
    /* margin-top: -5px; */
  }
  .navbar-toggler {
    padding: 7px 8px 7px;
    margin-top: -10px;
  }

}
@media (max-width: 346px){
  #logo {
    text-align: center;
  }
  a.active {
    color: #efecdb;
    padding-bottom: 5px;
    border-bottom: 3px solid #efecdb;
  }
  .navColor {
    height: 26px;
    transition: 0.5s;
  }
  .shrinkBar {
    background-image: linear-gradient(
      to right,
      #916c08 10%,
      #efecdb,
      #d29f14,
      #916c08
    );
    transition: 0.5s;
    font-size: 16px;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .navLogoCenter {
    justify-content: center;
    align-items: center;
    margin-left: 35px;
    margin-bottom: -2rem;
  }
  .logoSize {
    width: 45%;
    /* margin-top: -5px; */
  }
  .navbar-toggler {
    padding: 9px 8px 7px;
    margin-top: -5px;
  }

}
