body{
  margin: 1em;
  font-family: 'Cinzel', serif;
  line-height: 1.9rem;
  text-align: center;
  background-color: #242222;
  color: #dcddde;
}
p{
  font-family: 'Roboto', sans-serif;
}

.clear{
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}