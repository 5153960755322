#toplogo{
  width: 15%;
  max-width: 10rem;
  padding: 1rem 0;
}
header {
  padding-top: 0px;
  transition: 0.5s;
  background-color: #242222;
}

@keyframes animation {
  0%{
    width: 14%;
  }
  12.5%{
    width: 11%;
  }
  25%{
    width: 9%;
  }
  50%{
    width: 7%;
  }
  75%{
    width: 5%;
  }
  87.5%{
    width: 2%;
  }
  100%{ 
    width: 0%;
    visibility: hidden;
  }
}

@keyframes mediaAnimation {
  0%{
    width: 37%;
  }
  12.5%{
    width: 30%;
  }
  25%{
    width: 25%;
  }
  50%{
    width: 18%;
  }
  75%{
    width: 12%;
  }
  87.5%{
    width: 6%;
  }
  100%{ 
    width: 0%;
    visibility: hidden;
  }
}
.over1{
  animation-name: animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


@media (min-width: 399px) and (max-width: 540px) {
  .expanded {
    height: 50px;
  }

  #toplogo{
    padding-top: 10px;
    width: 37%;
  }
  .over1{
    animation-name: mediaAnimation;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  }
}
@media (max-width: 400px){

  #toplogo{
    padding-top: 18px;
    width: 41%
  }
}