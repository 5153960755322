.carouselJourney{
    margin-top: 2rem;
    padding: 2rem 3rem 6rem;
    background-color: #ffffff;
    color: #242222;
}

.images{
    padding: 0px 8px;
    border-radius: 15px;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
.bottom-left {

    position: absolute;
    padding-top: 8px;
    bottom: 1px;
    left: 9px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    background-color: #242222;
    opacity: .8;
    width: 96%;
    height: 15%;
    text-align: left;
    padding-left: 10px;
    border-radius: 5px;
  }
  .roomgalleryBox {
    position: relative;
    width: 96%;
    background: #ffffff;
}

@media (min-width: 601px) and (max-width: 710px){
    .images{
        padding: 2px 2px;
        border-radius: 10px;
    }
    .carouselJourney{
        padding: 2rem;
    }
    .bottom-left{
        font-weight: 400;
        font-size: 12px;
        width: 97.3%;
        bottom: 3px;
        left: 3px;
        padding-top: 0px;
    }
}

@media (min-width: 391px) and (max-width: 600px) {
    .images{
        padding: 5px 5px;
        border-radius: 10px;
    }
    .carouselJourney{
        padding: 2rem;
    }
    .bottom-left{
        font-weight: 400;
        font-size: 17px;
        width: 97.2%;
        bottom: 5px;
        left: 6px;
    }
}
@media (max-width: 390px){
    .images{
        padding: 5px 5px;
        border-radius: 10px;
    }
    .carouselJourney{
        padding: 1.8rem;
    }
    .bottom-left{
        font-weight: 400;
        font-size: 14px;
        width: 96%;
        bottom: 5px;
        left: 6px;
        padding-top: 2px;
    }
}
