.companyIcon{
    width: 35px;
    height: 35px;
}
a.companyLink{
    color: white;
}

a.companyLink:hover{
    color: #070707;
}

@media (max-width: 767px){
    .text-center{
        font-size: 12px;
    }
    .companyIcon{
        width: 26px;
        height: 26px;
    }
}