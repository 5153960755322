.carouselpos {
  margin-top: 178px;
  border: none;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0;
  width: 100vw;
}
.carouselScroll {
  margin-top: 71px;
  border: none;
  margin-left: -16px;
  margin-right: -16px;
  padding: 0;
  width: 100vw;
}
@media (max-width: 767px) {
  .carouselpos {
    margin-top: 140px;
  }
  .carouselScroll {
    margin-top: 38px;
  }
  .carouselpos img {
    aspect-ratio: 2.5;
  }
  .carouselScroll img{
    aspect-ratio: 2.5;
  }
}
