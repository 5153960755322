.card{
    padding: 1rem;
    margin: 3rem;
    background-image: linear-gradient(to right bottom, #684e05 10%, #b5a85e ,#a17c17, #684e05);
    color: #242222;
    border-radius: 0px;
}
.card-group{
    padding: 1rem 8rem 6rem;
}
.card-body{
    text-align: center;
}
.cardText{
    font-size: 21px;
    font-weight: 800;
    margin-top: 12px;
} 
.iconContentSpace{
    margin-top: 45px;
}

@media (min-width: 420px) and (max-width: 1350px){
    .card-group{
        padding: 1rem 2rem 3rem;
    }
    .card{
        margin: 1rem;
        min-height: 280px;
    }

}
@media (max-width: 419px){
    .card-group{
        padding: 1rem 1rem 3rem;
    }
    .card{
        margin: 1rem 0rem 1rem;
        min-height: 280px;
        padding: 10px;
    }
}