.content {
  text-align: center;
  padding: 50px;
}

.innerContent {
  text-align: center;
  line-height: 2.2rem;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
p {

}

@media (min-width: 1200px) {
  .innerContent {
    font-size: 22px;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 30px;
  }
}
/* If the screen size is smaller than 1200px, set the font-size to 80px */
@media (max-width: 620px) {
  .innerContent {
    font-size: 18px;
    padding-top: 10px;
    line-height: 1.8rem;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  .content {
    padding: 2rem 0.2rem 1rem;
  }
  h1 {
    font-size: x-large;
  }
  #lessSpace {
    padding: 0px;
  }
}
