.listItem{
  background-color: transparent;
  font-size: 17px;
  font-weight: 600;
  color:#242222
}

.leftGrid {
  padding-top: 3rem;
  text-align: left;
  margin: 0; 
  color: #242222;
}

.imageContainer {
  position: relative;
  z-index: 1;
}
.lowerDiv {
  position: absolute;
  bottom: -25px;
  right: -25px;
  width: calc(
    100% - 10px
  ); 
  height: calc(
    100% - 10px
  ); 
  border-right: 26px solid #916c08;
  border-bottom: 26px solid #916c08;
  z-index: -1;
}

.rows {
  padding: 3rem;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
#top {
  padding-top: 90px;
}

.watermark {
  position: relative;
  background-image: url("../imgs/watermarkF.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: -16px;
  margin-top: 15px;
}

@media (max-width: 767px) {
  #top{
    padding-top: 20px;
  }
  .rows {
    padding: 2rem;
  }
  .lowerDiv{
    bottom: -15px;
  right: -15px;
  width: calc(
    100% - 5px
  ); 
  height: calc(
    100% - 5px
  ); 
  border-right: 16px solid #916c08;
  border-bottom: 16px solid #916c08;
  }
  .listItem{
    font-size: small;
    line-height: 1.5rem;
  }
  .leftGrid{
    padding-bottom: 2rem;
  }
  .imageGridList {
    padding-left: 1rem;
  }
}

